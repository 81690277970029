import React from 'react'

import Layout from '../components/layout'
import HalfImage from './../components/halfImage/HalfImage'

import headerImage from './../images/header/leda-homecare-help-at-home.png'
import rightImage from './../images/main/7.png'
import UKHCA from './../images/main/UKHCA-black.jpg'
import careQuality from './../images/main/care-quality.png'

class AboutUs extends React.Component {
  componentDidMount() {
    const script = document.createElement('script')

    script.src =
      '//www.cqc.org.uk/sites/all/modules/custom/cqc_widget/widget.js?data-id=1-616510538&data-host=www.cqc.org.uk&type=location'
    script.async = true

    document.getElementById('qualityCareWidget').appendChild(script)

    const script1 = document.createElement('script')

    script1.src =
      'https://api.homecare.co.uk/assets/js/aggregate_rating.js?displayid=65432184764'
    script1.async = true
    script1.id = 'tg-aggregate-rating'
    script1.type = 'text/javascript'

    document.getElementById('homecareWidget').appendChild(script1)

    const script2 = document.createElement('script')

    script2.src =
      'https://api.homecare.co.uk/assets/js/review_widget.js?displaydiv=tgrw-aae2b00c&displayid=65432184764&displaycontent=snippet&displaywidth=300&displaycount=2&displayscore=true&displaylink=true&displayborder=true&displaybackgroundcolor=faded&displaypagination=false&displaystrapline=true&displayfontsize=default&displayallratings=false&displaylogo=true&displaywrappers=true&displaybutton=true&displaysettingname=true'
    script2.async = true
    script2.className = 'tg-review-widget'

    document.getElementById('homecareWidget').appendChild(script2)

    const display = document.createElement('div')

    display.className = 'tg-review-widget-container'
    display.id = 'tgrw-aae2b00c'

    document.getElementById('homecareWidget').appendChild(display)
  }

  render() {
    return (
      <Layout>
        <div className="about-us">
          <div
            className="header-image"
            style={{ backgroundImage: `url(${headerImage})` }}
          />
          <div
            className="container"
            style={{ marginTop: '100px', marginBottom: '60px' }}
          >
            <div className="row">
              <div className="twelve columns">
                <h2 className="center-text">About Us</h2>
                <p>
                  Leda Homecare Ltd is registered with the Care Quality
                  Commission (CQC), to provide domiciliary care services for
                  people in the comfort of their own homes. We also provide one
                  to one support for people and their families, who are unable
                  to gain respite care.
                </p>
                <p>
                  Our latest comments included the following:
                  <br />
                  &quot;The management of Leda Homecare place strong emphasis on
                  the highest standard and quality of service possible for all
                  our service users. We believe that no matter how good its
                  present services are there will always be room for improvement
                  and development. We aim to give the highest quality of service
                  in the safest environment where service users, families,
                  carers and staff are listened to and valued. &quot;
                </p>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <div
                    style={{
                      backgroundImage: 'url(' + UKHCA + ')',
                      backgroundSize: 'contain',
                      backgroundPosition: 'center',
                      backgroundRepeat: 'no-repeat',
                      height: '100px',
                      width: '100px',
                      margin: '10px',
                    }}
                  />
                  <div
                    style={{
                      backgroundImage: 'url(' + careQuality + ')',
                      backgroundSize: 'contain',
                      backgroundPosition: 'center',
                      backgroundRepeat: 'no-repeat',
                      height: '100px',
                      width: '150px',
                      margin: '10px',
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="row desktop-flex widgets">
              <div className="six columns">
                <div id="qualityCareWidget" />
              </div>
              <div className="six columns">
                <div id="homecareWidget" />
              </div>
            </div>
          </div>
          <HalfImage
            image={rightImage}
            imagePosition="right"
            background
            text={[
              <h2 key="1">Our Purpose</h2>,
              <p key="2">
                Our purpose is to promote independence, inclusion and well
                being. Our services are driven by, and focused on the needs,
                abilities and values of our customers.
              </p>,
              <h2 key="3">Our Principles</h2>,
              <p key="4">Our principles are to enable people to:</p>,
              <ul key="5">
                <li>Have control over their lives</li>
                <li>Choose their services</li>
                <li> Live safe, healthy and fulfilled lives</li>
                <li> Have an active role in their community</li>
                <li>
                  Access the support they need to be as independent as they
                  choose
                </li>
              </ul>,
            ]}
          />
          <div className="container">
            <div className="row">
              <div className="columns twelve">
                <h2 className="center-text"> Our Approach</h2>
                <p className="center-text">
                  Our services are about the people who use them. We aim to
                  provide the leadership to ensure quality services, value our
                  staff by giving them the right support, skills and resources
                  and effectively manage our performance. We wish to be
                  recognised as innovative in home care services by working in
                  collaboration with our service users, their families and
                  friends. We acknowledge that we are professional visitors in
                  our service users’ domain and so we will treat everybody with
                  the respect, tolerance and empathy they are entitled to expect
                  - service users, families and colleagues alike. We will
                  encourage a safe environment, without compromising the rights
                  and autonomy of our service users to take calculated risks
                  wherever possible. Working in partnership with you, Leda
                  Homecare offers a complete range of cost effective services,
                  tailored precisely to your needs.
                </p>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default AboutUs
